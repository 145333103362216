/* Fonts */

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2)
    format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fBBc4.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu72xKOzY.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu5mxKOzY.woff2)
    format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu7mxKOzY.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4WxKOzY.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu7WxKOzY.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu7GxKOzY.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxK.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2)
    format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fCBc4EsA.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fBxc4EsA.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fCxc4EsA.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fBBc4.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2)
    format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfCBc4EsA.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfBxc4EsA.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfCxc4EsA.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfBBc4.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body {
  overflow-x: hidden !important;
}

.control-dots {
  text-align: left !important;
  padding-left: 3.7rem !important;
  padding-right: 3% !important;
}

.control-dots .dot.selected {
  background-color: #ffcb08 !important;
  opacity: 1 !important;
}

.control-dots .dot {
  background-color: #ffffff !important;
  opacity: 0.5 !important;
  width: 13px !important;
  height: 13px !important;
}
.not-found-container {
  padding: 100px;
  text-align: center;
}
.bullet_list_content li {
  padding-bottom: 10px;
  padding-left: 20px;
  line-height: 32px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.5px;
  color: #5b5b5b;
}

@media only screen and (max-width: 37.5em) {
  .bullet_list_content li {
    font-size: 16px;
    line-height: 28px;
  }
}

.Home_news_silder .carousel .slide {
  text-align: start !important;
}

.loan_slab_values p:first-child {
  border-bottom: 2px solid #eaeaea;
  margin: 0;
  padding: 5px 0px;
}

.not-found-container {
  padding: 100px;
  text-align: center;
}

.loan_slab_values p:nth-child(2) {
  margin: 0;
  padding: 8px 0px;
}

.roi_product_name_content {
  margin-left: 30%;
  margin-right: 30%;
}

@media only screen and (max-width: 767px) {
  loan_slab_values div:nth-child(3) {
    padding: 0;
    width: 20%;
  }
}

@media only screen and (min-width: 950px) {
  loan_slab_values div:nth-child(3) {
    padding: 0;
    width: 40%;
  }
}

.loan_slab_values div:nth-child(4) {
  width: 10%;
}

@media only screen and (max-width: 767px) {
  loan_slab_values div:nth-child(4) {
    padding: 0;
    width: 50%;
  }
}

.interest_rate_values p:first-child {
  border-bottom: 2px solid #eaeaea;
  margin: 0;
  padding: 5px 0px;
}

.interest_rate_values p:nth-child(2) {
  margin: 0;
  padding: 8px 0px;
  width: 33%;
}

.interest_rate_values div:nth-child(3) {
  width: 33%;
}

.interest_rate_values div:nth-child(4) {
  width: 33%;
}

.NoteSection p {
  padding: 0;
  margin: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
}

#property-cost-grid {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

#property-cost-grid-value {
  max-width: none;
}

.list-component-li {
  padding: 16px !important;
}

.accordianOuterDiv .MuiAccordion-root.Mui-expanded {
  background: #ffffff;
  box-shadow: 4px 9px 47px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.accordianOuterDiv .MuiAccordion-rounded {
  background: #ffffff;
  box-shadow: 4px 9px 47px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

#top-row {
  justify-content: space-between;
}

#icon {
  display: none !important;
}

.card_list_inner_li li {
  line-height: 28px;
  margin-bottom: 10px;
  color: #5b5b5b;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.card_list_inner_li p {
  line-height: 28px;
  margin-bottom: 10px;
  color: #5b5b5b;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.MuiInput-underline:before {
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(226, 226, 226, 1) !important;
}

.bullet_list_content_sub ul li {
  list-style: none;
  line-height: 20px;
  padding-left: 10px;
  color: #5b5b5b !important;
}

.bullet_list_content_sub ul {
  padding-left: 0;
  margin-top: 2%;
}

.bullet_list_content_sub ul li::before {
  content: '\2022';
  color: gray;
  font-weight: bold;
  width: 20em;
  margin-right: 0.5em;
}

.bullet_list_content_sub li {
  padding-bottom: 10px;
  padding-left: 20px;
  line-height: 32px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #000000;
}

#grievance_res_div .car_lis_ul {
  list-style: none;
  padding-left: 0;
}

#corp_governance_div .black_descp_b b {
  color: #000000;
}

#commitees .black_descp_b ul b {
  float: left;
  width: 100%;
  margin: 3% 2%;
}

#commitees .margin_h3 h3 {
  margin: 0%;
}

.corona_guidance .black_descp_b p {
  line-height: 58px;
  font-family: 'Roboto';
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 767px) {
  .corona_guidance .black_descp_b p {
    font-family: 'Roboto';
    letter-spacing: 0.5px;
  }
}

#commitees .margin_p p {
  margin-top: 0%;
  margin-bottom: 20%;
  size: 18px;
  line-height: 28.5px;
  color: #5b5b5b;
}

#commitees .designation_p p {
  margin: 10% 0%;
  size: 18px;
  line-height: 28.5px;
  color: #5b5b5b;
}

/* #commitees .black_descp_b li {
  line-height: 54px;
} */

#grievance_res_div .car_lis_ul b::before {
  content: url('../assets/images/rightarrowbullet.png') !important;
  padding-left: 0;
  width: 20em;
  margin-right: 8%;
  align-content: center;
  align-items: start;
}

#grievance_res_div .car_lis_ul b {
  color: #000000;
}

.iXgSZB {
  padding-bottom: 0px !important;
}

.circle {
  flex-direction: row;
  display: flex;
}

.circleText {
  font-size: 8px;
  font-family: 'Roboto';
  color: red;
}

.link_button_a a {
  text-decoration: none;
  color: #00529c;
}

.commitee_style li {
  line-height: 50px;
}

.commitee_style ol li {
  line-height: 28px;
}

.emailForCards {
  text-decoration: none;
  color: #00529c;
}

.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden;
  /* Will prevent background page from scrolling when React modal is open */
}

.share-holders-main-div .contact-details {
  margin-top: 5%;
  min-height: 0px;
}

.share-holders-main-div .email-details {
  margin-top: 5%;
  min-height: 0px;
}

.share-holders-main-div .lic-description {
  margin-top: 2%;
  min-height: 0px;
}

.listing-equity-shares-div .contact-details {
  margin-top: 0px;
  min-height: 0px;
}

.listing-equity-shares-div .email-details {
  margin-top: 5%;
  min-height: 0px;
}

.listing-equity-shares-div .script-details {
  margin-top: 5%;
  margin-bottom: 2%;
  min-height: 0px;
}

.debentures-div .contact-details {
  margin-top: 5%;
  min-height: 0px;
}

.debentures-div .email-details {
  margin-top: 5%;
  margin-bottom: 2%;
  min-height: 0px;
}

.debentures-div .lic-description {
  margin-top: 2%;
  min-height: 0px;
}

.card_list_inner_li a {
  text-decoration: none;
  color: #00529c;
}

.list-equity-shares .email-details {
  margin-top: 0px;
  min-height: 0px;
}

.heading {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.5px;
  color: #000000;
  margin: 0px;
}

.description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #5b5b5b;
  margin: 8px 0px 0px 0px;
}

.debentures-main-div .email-details {
  margin-top: 0px;
  min-height: 0px;
}

.home_page_first_pop_up,
.home_page_footer_pop_up {
  z-index: 3;
}

.home_page_first_pop_up .MuiDialogContent-root {
  padding-top: 0 !important;
}

.container-grid {
  flex-wrap: nowrap !important;
  width: 500% !important;
  overflow-x: scroll;
}

.container-grid-small {
  flex-wrap: nowrap !important;
  width: 250% !important;
  overflow-x: scroll;
}

.phone-row {
  flex-direction: row;
  display: flex;
}

.desktop-row {
  flex-direction: row;
  display: flex;
  margin-left: 15px;
}

.websiteForCards {
  text-decoration: none;
  color: #5b5b5b;
}

.sidebar-scroll {
  height: 80vh;
}

.customer-sidebar-scroll {
  height: 100vh;
}

.sidebar-scroll::-webkit-scrollbar {
  width: 5px;
}

.sidebar-scroll::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.sidebar-scroll::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

.shareholders-desk-div .description b {
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .listing-equity-shares-div .card-grid .MuiGrid-grid-xs-6 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .debentures-div .card-grid .MuiGrid-grid-xs-6 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
}

.sf-chatbot-container {
  overflow: visible !important;
}

.sf-bot-icon {
  background: url(../assets/images/askhomy.png) center no-repeat !important;
  height: 100px !important;
  width: 100px !important;
  /* background-attachment: fixed !important; */
  /* background-position: bottom 50px right -10px !important; */
  right: -10px !important;
  bottom: 10px !important;
  overflow: visible !important;
  z-index: 3 !important;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@media only screen and (max-width: 949px) {
  .sf-bot-icon {
    background: url(../assets/images/askhomy.png) center no-repeat !important;
    /* background-attachment: fixed !important; */
    /* background-position: bottom 50px right -10px !important; */
    right: 0px !important;
    bottom: 0px !important;
    overflow: visible !important;
    z-index: 2;
  }
}

.emailMargin {
  margin-top: 0px !important;
  min-height: 0px !important;
}

.card_slider a {
  text-decoration: none;
  color: #00529c;
}

.home_desc_link a {
  text-decoration: none !important;
  color: var(--primary) !important;
}

.customer_card a::after {
  content: url('../assets/images/customerright.png') !important;
  width: 20em;
  align-content: center;
  align-items: center;
  align-self: center;
  margin-left: 1%;
}

.gsc-adBlock {
  display: none !important;
}

/* #directionBtn a span {
  color: var(--primary);
}

#directionBtn:hover {
  background: linear-gradient(
    301.97deg,
    #024182 1.86%,
    rgba(2, 65, 130, 0.64) 98.56%
  ) !important;
  border: none;
  color: #fff;
}

#directionBtn a span:hover {
  color: #fff;
} */
.MuiButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.MuiPopover-paper {
  /* margin-left: 100px !important; */
}

.csSideLink:hover {
  background-color: transparent !important;
  color: #00529c;
}

.csSideLink:hover {
  background-color: transparent !important;
  color: #00529c;
}

.fixed-position {
  overflow-y: hidden;
}

@media (min-width: 951px) and (max-width: 1025px) {
  .main-top-links li a {
    padding: 0.3rem 0 0.3rem 0.2rem !important;
  }
}

.overlay::after {
  position: absolute;
  z-index: -1 !important;
  height: 100%;
}

.overlay::before {
  position: absolute;
  z-index: -1 !important;
  height: 100%;
}

iframe {
  position: unset !important;
  padding: 20px;
}

.activeWhatsapp {
  color: #fff !important;
  background: linear-gradient(
    301.97deg,
    #024182 1.86%,
    rgba(2, 65, 130, 0.64) 98.56%
  );
  box-shadow: 4px 9px 52px rgb(0 0 0 / 8%);
  border-radius: 5px !important;
  align-self: center;
  height: 45px;
  text-transform: capitalize !important;
}

.activeWhatsapp .MuiButton-label {
  color: #fff !important;
}

.inactivesubmitbtn {
  color: #fff !important;
}
